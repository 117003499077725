import CookieBanner from 'components/CookieBanner'
import Footer from 'components/Footer'
import {history, LocationMatch} from 'components/Router'
import {Dealer} from 'domain-models'
import {applyTheme, isAudiSite, isNissanSite, isSkodaSite, isUsingIE, isVolkswagenSite, track} from 'hooks'
import {find, first, get, isEmpty, toInteger} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {getStoredAppBoolean, getStoredAppObject, getStoredAppString, getStoredVehicleObject, storeAppItem, storeLeadItem, storeVehicleInterest, storeVehicleItem} from 'redux/actions'
import {classNames} from './utils/class-names'

type AppProps = {
  children: any
  match: LocationMatch
}

type DealersResponse = {
  status: 'ok' | 'failed'
  code: null | string
  result: {
    dealers: Array<Dealer>
  }
}

export enum Themes {
  VW,
  NISSAN,
  AUDI,
  SKODA
}

export const THEMES: Record<Themes, string> = {
  [Themes.VW]: 'theme-vw',
  [Themes.NISSAN]: 'theme-nissan',
  [Themes.AUDI]: 'theme-audi',
  [Themes.SKODA]: 'theme-skoda',
}

export type AppConfig = {
  disableManualRequests?: boolean
}

export type AppType = {
  dealer?: Dealer
  config?: AppConfig
}

export const AppContext = React.createContext({
  app: {} as AppType,
  setApp: null,
} as { app: AppType; setApp: CallableFunction | null })

const App = ({match, children}: AppProps) => {
  const [app, setApp] = useState<AppType>({})
  const context = {app, setApp}

  const initCodes = useCallback(async () => {
    if (!get(getStoredAppObject('codes', {}, false), 'Make', false)) {
      const codes = await window.axios.get('/api/get-codes')
      storeAppItem('codes', codes.data)
    }
  }, [])

  const initConfig = useCallback(async () => {
    let config = getStoredAppObject('config', {}, false)
    if (isEmpty(config)) {
      const response = await window.axios.get('/api/get-config')
      config = response.data || {}
      storeAppItem('config', config)
    }
    setApp({...app, config})
  }, [])

  const initStatistics = useCallback(async () => {
    const statisticEnabled = getStoredAppBoolean('statistic')

    if (statisticEnabled && !getStoredAppBoolean('gaLoaded', false, false)) {
      // await loadGaScript()
    }

    if (statisticEnabled && !getStoredAppString('trackingId')) {
      const trackingResponse = await window.axios.get('/api/tracking-id')

      storeAppItem('trackingId', trackingResponse.data)
    }
  }, [])

  const changeDealer = (dealer: Dealer) => {
    context.setApp({dealer: dealer})
    const currentDealer = getStoredVehicleObject('dealer') as Dealer
    if (currentDealer && currentDealer.DealerId === dealer.DealerId) {
      return
    }
    storeVehicleItem('dealer', dealer)
  }

  const initDealers = useCallback(async () => {
    let dealers = [] as Array<Dealer>

    if (process.env.REACT_APP_RELEASE_STAGE !== 'production') {
      setTimeout(async () => {
        const testDealer = {DealerId: 12345, Id: 12345, Firm: 'TestFirm', FirmExtra: 'TestFirmExtra', IsDefault: true} as Dealer
        storeAppItem('defaultDealers', [testDealer])
        changeDealer(testDealer)
      }, 1500)
      return
    }

    dealers = (getStoredAppObject('defaultDealers', []) || []) as Array<Dealer>

    const alreadyInitialized = !!(match.params.domain && localStorage.getItem('initialized-dealer-domain') === match.params.domain && dealers && dealers.length > 0)

    if (match.params.domain) {
      if (!alreadyInitialized) {
        const response = await window.axios.get<DealersResponse>('/api/get-dealers/' + (match.params.domain || ''))
        dealers = response.data.result.dealers
      }

      if (dealers) {
        localStorage.setItem('initialized-dealer-domain', match.params.domain)
      }
      storeAppItem('defaultDealers', dealers)
      const storedDealer = getStoredVehicleObject('dealer') as Dealer

      if (!find(dealers, {Id: storedDealer.Id})) {
        const firstDealer = first(dealers)
        const dealer = find(dealers, {IsDefault: true})
        changeDealer(dealer || firstDealer || {} as Dealer)
      }
    }

    let dealer = null
    if (match.params.dealerId && dealers) {
      dealer = find(dealers, {DealerId: toInteger(match.params.dealerId)})

      if (dealer && typeof dealer !== 'undefined') {
        changeDealer(dealer)
      }
    }

    if (dealers && dealers.length === 1) {
      const firstDealer = first(dealers)
      const storedDealer = getStoredVehicleObject('dealer') as Dealer
      if (firstDealer && firstDealer?.DealerId !== storedDealer?.DealerId) {
        changeDealer(firstDealer)
      }
    }
  }, [match.params.dealerId, match.params.domain])

  const trackSiteView = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const referrer = urlParams.get('referrer')

    if (referrer) {
      storeAppItem('referrer', referrer)
    }

    track('site-view', {path: history.location.pathname, referrer: referrer || getStoredAppString('referrer') || document.referrer}, true)
  }

  useEffect(() => {
    trackSiteView()
  }, [history.location.pathname])

  useEffect(() => {
    initDealers()
  }, [initDealers])

  const storeIntend = () => {
    const urlParams = new URLSearchParams(window.location.search)

    const intentionMap = {
      null: 'no-intention',
      1: 'new-vehicle-purchase',
      2: 'used-vehicle-purchase',
    } as any

    const intention = urlParams.get('intention')
    if (intention) {
      storeLeadItem('intention', intentionMap[intention] || intention)
    }

    const params = {} as any
    urlParams.forEach((value, field) => params[field] = value)

    if (params?.Marke) {
      storeVehicleInterest(params)
    }

    if (params?.Modell && !params?.EZ && !params?.PreOwn) {
      storeLeadItem('intention', 'new-vehicle-purchase')
    }
    if (params?.Modell && (params?.EZ || params?.PreOwn)) {
      storeLeadItem('intention', 'used-vehicle-purchase')
    }
  }

  const setTheme = () => {
    let initialTheme = Themes.VW

    if (isVolkswagenSite()) {
      initialTheme = Themes.VW
    }

    if (isAudiSite()) {
      initialTheme = Themes.AUDI
    }

    if (isSkodaSite()) {
      initialTheme = Themes.SKODA
    }

    if (isNissanSite()) {
      initialTheme = Themes.NISSAN
    }

    storeAppItem('theme', initialTheme)
    applyTheme(initialTheme)
  }

  useEffect(() => {
    setTheme()

    initConfig()

    trackSiteView()

    storeIntend()

    initCodes()
    initStatistics()
  }, [])

  return (
    <AppContext.Provider value={context}>
      <div className={classNames(
        'app container mx-auto',
        isUsingIE() ? '' : 'block md:flex flex-col justify-between h-full',
      )}>
        {children}

        <div className={'mt-4'}>
          <Footer/>
        </div>

        <CookieBanner match={match}/>
      </div>
    </AppContext.Provider>
  )
}

export default App
